import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HochzeitGallery from "./components/HochzeitGallery";
import "./index.css";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HochzeitGallery />} />
        <Route path="*" element={<Navigate to="/" />} />{" "}
        {/* Redirect bei 404 */}
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState } from "react";
import { images } from "../images";
import { thumbnails } from "../thumbnails";
import Login from "./Login";

// yet-another-react-lightbox
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// react-photo-album
import { Photo, RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import StyledLink from "./StyledLink";
import SelectIcon from "./SelectIcon";
import DownloadSelectedFiles from "./DownloadSelectedFiles";
import DownloadZipFile from "./DownloadZipFile";

type SelectablePhoto = Photo & {
  selected?: boolean;
};

const HochzeitGallery = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // photos array
  const [photos, setPhotos] = useState<SelectablePhoto[]>(() =>
    thumbnails.map((photo) => ({
      ...photo,
      href: photo.src,
    })),
  );

  const [largePhotos, setLargePhotos] = useState<SelectablePhoto[]>(
    images.map((photo) => ({
      ...photo,
      href: photo.src,
    })),
  );

  const selectedPhotos = largePhotos.filter((item) => item.selected === true);
  // lightbox photo
  const [index, setIndex] = useState(-1);

  return (
    <div>
      {!isAuthenticated ? (
        <Login onLogin={setIsAuthenticated} />
      ) : (
        <>
          <div className="flex flex-col items-center space-y-4 bg-white text-gray-800 text-center p-4 rounded-lg shadow-md">
            <h1 className="text-4xl font-semibold">
              Hochzeitsgalerie von Vanessa & Stefan (23.08.2024)
            </h1>
            <h3 className="text-2xl font-semibold">Kurze Anleitung:</h3>
            <p>
              Bilder anklicken für eine große Ansicht. In der Galerie kann man
              eine Diashow starten oder selbst mit den Pfeiltasten navigieren.
            </p>
            <p>
              Ihr könnt alle Bilder gezipped auf einmal herunter laden oder die
              Bilder selbst auswählen.
            </p>
            <p>
              Dazu müsst ihr oben rechts im Kreis die gewünschten Bilder
              selektieren und am Ende auf den Button klicken.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-4">
              <DownloadSelectedFiles
                images={selectedPhotos}
                text="selektierte Bilder herunterladen"
              />
              <DownloadZipFile />
            </div>
          </div>
          <>
            <RowsPhotoAlbum
              photos={photos}
              targetRowHeight={150}
              // custom render functions
              render={{
                // render custom styled link
                link: (props) => <StyledLink {...props} />,
                // render image selection icon
                extras: (_, { photo: { selected }, index }) => (
                  <SelectIcon
                    selected={selected}
                    onClick={(event) => {
                      // changes selected status
                      setPhotos((prevPhotos) => {
                        const newPhotos = [...prevPhotos];
                        newPhotos[index].selected = !selected;
                        return newPhotos;
                      });

                      setLargePhotos((prevPhotos) => {
                        const newPhotos = [...prevPhotos];
                        newPhotos[index].selected = !selected;
                        return newPhotos;
                      });

                      // prevent the event from propagating to the parent link element
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                  />
                ),
              }}
              // custom components' props
              componentsProps={{
                link: ({ photo: { href } }) =>
                  // add target="_blank" and rel="noreferrer noopener" to external links
                  href?.startsWith("http")
                    ? {
                        target: "_blank",
                        rel: "noreferrer noopener",
                      }
                    : undefined,
              }}
              // on click callback
              onClick={({ event, index }) => {
                // let a link open in a new tab / new window / download
                if (event.shiftKey || event.altKey || event.metaKey) return;

                // prevent the default link behavior
                event.preventDefault();
                setIndex(index);
              }}
              // describe photo album size in different viewports
              sizes={{
                size: "1168px",
                sizes: [
                  {
                    viewport: "(max-width: 1200px)",
                    size: "calc(100vw - 32px)",
                  },
                ],
              }}
              // re-calculate the layout only at specific breakpoints
              breakpoints={[220, 360, 480, 600, 900, 1200]}
            />

            <Lightbox
              slides={photos}
              open={index >= 0}
              index={index}
              close={() => {
                setIndex(-1);
              }}
              carousel={{ finite: true }}
              render={{ buttonPrev: () => null, buttonNext: () => null }}
              styles={{
                root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" },
              }}
              controller={{
                closeOnBackdropClick: true,
                closeOnPullUp: true,
                closeOnPullDown: true,
              }}
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Download]}
            />
          </>
        </>
      )}
    </div>
  );
};

export default HochzeitGallery;

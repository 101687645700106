import React from "react";

const DownloadSelectedFiles = ({ images, text }) => {
  const handleDownload = () => {
    images.forEach(({ src }, index) => {
      setTimeout(() => {
        const tempLink = document.createElement("a");
        tempLink.href = src;
        tempLink.download = "";

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }, index * 200); // Verzögerung von 200 Millisekunden zwischen den Downloads
    });
  };

  return (
    <button
      className="text-white bg-blue-500 hover:bg-blue-700 p-2 rounded-md"
      onClick={handleDownload}
    >
      {text}
    </button>
  );
};

export default DownloadSelectedFiles;

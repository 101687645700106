import React from "react";

const DownloadZipFile = () => (
  <a
    href="/Hochzeit.zip"
    download
    className="text-white bg-blue-500 hover:bg-blue-700 p-2 rounded-md"
  >
    Alle Bilder als .zip herunterladen (3,9 GB)
  </a>
);

export default DownloadZipFile;
